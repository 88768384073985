@use 'mixins' as mix;

%list {
  @include mix.spaceY(0.5rem);

  li {
    @include mix.flex('row', 'flex-start');

    gap: 0.5rem;
    line-height: 1.2;
  }
}
