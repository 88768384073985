.spinner {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 10%);
  z-index: 1302;

  svg {
    height: 100px;
    width: 100px;
  }
}
