@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.modal {
  display: grid;
  gap: var.$spacing-regular;
  background: var.$white;
  padding: var.$spacing-regular;

  @media screen and (min-width: var.$mid-width) {
    padding: var.$spacing-lg;
  }

  .header {
    display: grid;
    gap: var.$spacing-sm;

    .logo {
      height: 1.25rem;
      width: auto;
      margin-bottom: var.$spacing-sm;
    }

    h2 {
      @extend %h1;
    }

    p {
      letter-spacing: normal;
    }
  }

  .nav {
    margin: var.$spacing-sm -#{var.$spacing-regular};

    @media screen and (min-width: var.$small-width) {
      margin: var.$spacing-sm 0;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      align-items: flex-end;
      text-align: center;

      @media screen and (min-width: var.$small-width) {
        grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
        gap: var.$spacing-sm;
      }

      span {
        @include mix.flex(
          $inline: true,
          $justify: center,
          $gap: var.$spacing-compact
        );

        width: 100%;
        padding: var.$spacing-compact var.$spacing-regular;
        border-bottom: 2px solid var.$lightNeutral;
        text-transform: uppercase;
        font-size: 0.625rem;
        font-weight: 600;
        letter-spacing: 0.15px;
        color: var.$gray;
        opacity: 0.5;

        &.active {
          border-color: var.$on3primary;
          color: var.$on3primary;
          opacity: 1;
        }

        .checkmark {
          color: currentcolor;
          font-size: 1.2em;
        }
      }
    }
  }

  .form {
    input:not([data-ui='autocomplete'] input) {
      @extend %input;
    }

    select {
      @extend %select;
    }

    [data-ui='checkbox'] {
      @extend %checkbox;
    }

    [data-ui='autocomplete'] {
      @extend %autocomplete;
    }

    footer {
      @include mix.flex($justify: flex-end);

      padding-top: var.$spacing-sm;

      button[type='submit'] {
        @extend %btn-primary;
      }
    }
  }
}
