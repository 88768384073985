@use '@on3/styles/elite/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.form {
  display: grid;
  gap: var.$spacing-regular;

  .footer:has(.skip) {
    gap: var.$spacing-regular;

    .skip {
      @extend %link;
    }
  }
}

.profilePhoto {
  .interactive {
    display: grid;
    grid-template-columns: 1fr;
    gap: var.$spacing-regular;
    align-items: center;
    justify-items: center;

    @media screen and (min-width: var.$small-width) {
      grid-template-columns: 150px 1fr;
    }

    .avatar {
      height: 150px;
      width: 150px;
      border-radius: var.$radius-sm;
      border: 2px solid var.$focusBackground;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .error {
      @extend %error;
    }

    .upload {
      @include mix.flex($direction: column, $gap: var.$spacing-sm);

      @media screen and (min-width: var.$small-width) {
        align-items: flex-start;
      }

      p {
        letter-spacing: normal;
      }

      div[role='button'] {
        @extend %btn-primary, %btn-icon;
      }
    }
  }
}
