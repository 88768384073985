@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.base {
  display: grid;
  animation: spinner 1s linear infinite;
}
