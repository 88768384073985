@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.base {
  label {
    @include mix.flex(
      $justify: 'flex-start',
      $align: 'center',
      $gap: 0.5rem,
      $inline: true
    );

    letter-spacing: normal;
    cursor: pointer;
  }

  [role='checkbox'] {
    @include mix.flex($inline: true, $justify: 'center');

    height: 1.25rem;
    width: 1.25rem;
    border-radius: var.$radius-sm;
    border: 1px solid var.$gray;

    svg {
      fill: transparent;
      height: 0.8em;
    }

    &[aria-checked='true'] {
      background-color: var.$black;
      border-color: var.$black;

      svg {
        fill: var.$white;
      }
    }

    &[aria-disabled='true'] {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}
