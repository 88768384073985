@use '@on3/styles/elite/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.modal {
  text-align: center;
  letter-spacing: normal;
  background-color: #fff;
  padding: 2rem 1rem;

  @include mix.spaceY(1rem);

  @media screen and (min-width: var.$tablet-width) {
    padding: 2rem;
  }

  h2 {
    font-size: clamp(1.25rem, 2vw, 1.5rem);
    margin-bottom: 2rem;
    text-align: center;
  }

  .form {
    display: grid;
    gap: 1rem;

    label {
      text-align: left;
      display: grid;
      gap: 2px;

      span {
        font-family: var.$archivo-narrow;
        font-weight: 900;
        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }

    select,
    input,
    textarea {
      font-family: var.$geist;
      font-size: 1rem;
      border: 1px solid transparent;
      border-radius: var.$radius-sm;
      padding: 0.75rem;
      width: 100%;
      background-color: var.$lightNeutralGray;

      &:focus {
        border-color: var.$on3primary;
        outline: none;
      }
    }

    .submit {
      @include mix.spaceY(1rem);

      button {
        @extend %btn-primary;
      }
    }
  }

  .error {
    font-size: 0.75rem;
    text-align: left;
    color: var.$negativeDay;
  }

  .success {
    text-align: center;

    @include mix.spaceY(1rem);

    button {
      @extend %btn-gray;
    }
  }
}
