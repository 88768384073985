@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.base {
  position: relative;

  label {
    display: grid;
    gap: 2px;
  }

  .wrapper {
    @include mix.flex($align: stretch);

    position: relative;

    .prefix {
      display: inline-grid;
      place-items: center;
      border: 1px solid var.$lightNeutralGray;
      border-top-left-radius: var.$radius-sm;
      border-bottom-left-radius: var.$radius-sm;

      & + .inner {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  [data-ui='autocomplete-label'] {
    @extend %label;
  }

  [data-autocomplete-ui='interactive'] {
    @extend %input;

    @include mix.flex($wrap: 'wrap');

    gap: 0.25rem;
    cursor: text;

    &:has(input[disabled]),
    &:not(:has(input)) {
      cursor: auto;
    }

    &:has(.chip) {
      padding: calc(0.75rem - var.$spacing-compact) 1rem;
      padding-left: var.$spacing-compact;
    }

    &:has(input[disabled]),
    &:not(:has(input)) {
      cursor: auto;
    }

    &:has(.chip) {
      padding: calc(0.75rem - var.$spacing-compact) 1rem;
      padding-left: var.$spacing-compact;
    }

    [data-autocomplete-ui='input'] {
      @include mix.flex;

      min-width: 100%;
      width: 100%;
      flex: 1;

      @media screen and (min-width: var.$mid-width) {
        min-width: 33%;
        width: auto;
      }
    }

    .chip {
      @include mix.flex;

      @extend %truncate-text;

      gap: 0.5rem;
      font-size: 0.875rem;
      font-family: var.$geist;
      font-weight: 500;
      letter-spacing: 0.15px;
      text-transform: none;
      background-color: var.$lightNeutral;
      padding: var.$spacing-compact;
      padding-left: 0.75rem;
      border-radius: var.$radius-xl;
      cursor: pointer;

      span {
        @extend %truncate-text;
      }
    }

    input {
      padding: 0;
      border-radius: 0;
      border: 0;
      background-color: transparent;
      flex: 1;
      font-size: 1rem;
      line-height: 1.25;
      min-width: 50%;

      &:focus {
        border-color: transparent;
        outline: none;
      }
    }
  }

  .dropdown {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 100%;
    transform: translateY(2px);
    background-color: #fff;
    display: grid;
    border-radius: var.$radius-md;
    box-shadow: 0 1px 0.25rem rgb(0 0 0 / 10%);
    max-height: 200px;
    overflow: auto;
    list-style: none;
    padding: 0;
    margin-top: 0;

    .option {
      width: 100%;
      padding: 0.75rem 1rem;
      text-align: left;
      font-size: 1rem;
      border: 0;

      &:hover {
        background-color: var.$lightNeutral;
      }
    }
  }

  .helper {
    @extend %helper-text;
  }

  .error {
    @extend %error;
  }
}
