@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.modal {
  border-radius: var.$radius-md;
  background-color: #fff;
  overflow: hidden;

  .banner {
    display: grid;
    place-items: center;
    height: 200px;
    background-color: var.$on3primary;
    width: 100%;
  }

  .content {
    display: grid;
    gap: var.$spacing-regular;
    padding: var.$spacing-lg;
  }

  header {
    display: grid;
    gap: var.$spacing-regular;
  }

  h2 {
    @extend %h1;

    color: var.$on3primary;
  }

  p {
    letter-spacing: normal;
  }

  .link {
    @extend %btn-primary, %btn-icon;

    justify-content: center;

    svg {
      display: none;
    }

    &:not(a) {
      pointer-events: none;
      opacity: 0.25;

      svg {
        display: inline;
        animation: spin 1s linear infinite;
      }
    }
  }
}
