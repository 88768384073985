@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.base {
  &.isDisabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  label {
    @extend %label;

    display: grid;
    gap: 2px;
  }

  [data-ui='select-label'] {
    &.required {
      &::after {
        content: '*';
        margin-left: var.$spacing-xs;
      }
    }
  }

  select {
    @extend %select;
  }

  .error {
    @extend %error;
  }
}
