.avatar {
  border-radius: 3px;
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
}

.teamAvatar {
  border-radius: 0;
  object-fit: contain;
}
