@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  display: grid;
  row-gap: var.$spacing-regular;
  letter-spacing: normal;
  padding: var.$spacing-regular;
  background-color: #fff;
  border-radius: var.$radius-sm;

  header {
    @include mix.spaceY(var.$spacing-compact);

    h2 {
      @extend %h2;
    }

    .school {
      @include mix.flex($gap: var.$spacing-sm);

      font-size: 0.75rem;
      font-weight: 500;

      figure {
        line-height: 0;
      }

      img {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }

  .actions {
    @include mix.flex($justify: flex-end, $gap: var.$spacing-sm);

    .addBtn,
    .addCoachBtn {
      @extend %link;
    }
  }

  .visits {
    display: grid;
    gap: var.$spacing-sm;

    .visit {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas: 'date remove' 'type type';
      gap: var.$spacing-sm;

      & + .visit {
        border-top: 1px solid var.$lightNeutral;
        padding-top: var.$spacing-sm;
      }

      input[type='date'] {
        @extend %input-date;
      }

      .date {
        grid-area: date;
      }

      .type {
        grid-area: type;
      }

      .remove {
        grid-area: remove;
        align-self: flex-end;
      }
    }

    .removeBtn {
      height: 3rem;
      width: 2rem;

      svg {
        height: 1.25rem;
        width: 1.25rem;
        fill: var.$on3primary;
      }
    }
  }

  [data-ui='checkbox'] {
    @extend %checkbox;
  }

  [data-autocomplete-ui='interactive'] {
    @extend %input;
  }

  .saveBtn {
    @extend %btn-primary;

    justify-self: flex-end;
  }
}
