@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  letter-spacing: normal;
  padding: var.$spacing-regular;
  background-color: #fff;
  border-radius: var.$radius-sm;

  h2 {
    @extend %h2;

    margin-bottom: var.$spacing-regular;
  }

  h3 {
    @extend %h3;

    margin-bottom: var.$spacing-compact;
  }

  .meta {
    @include mix.flex;

    gap: 1rem;
    margin-bottom: 1rem;

    .avatar {
      border-radius: var.$radius-sm;
    }

    .vitals {
      .name {
        font-weight: 700;
      }
    }
  }

  .form {
    @include mix.spaceY(1.5rem);

    & > div {
      @include mix.spaceY(0.75rem);
    }

    .submit {
      @include mix.flex($justify: 'flex-end');

      button {
        @extend %btn-primary;
      }
    }
  }
}
