@use '@on3/styles/elite/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.modal {
  letter-spacing: normal;
  background-color: #fff;
  padding: 2rem 1rem;

  @include mix.spaceY(1rem);

  @media screen and (min-width: var.$tablet-width) {
    padding: 2rem;
  }

  h2 {
    font-size: clamp(1.25rem, 2vw, 1.5rem);
    margin-bottom: 2rem;
    text-align: center;
  }

  .subheading {
    font-size: clamp(1rem, 2vw, 1.125rem);
    font-weight: 900;
    line-height: 1.2;
  }

  ul {
    @extend %list;

    .check {
      flex-shrink: 0;

      svg {
        fill: var.$on3primary;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  .form {
    display: grid;
    gap: 1rem;

    h3 {
      font-weight: 900;
    }

    label {
      display: grid;
      gap: 2px;

      span {
        font-family: var.$archivo-narrow;
        font-weight: 900;
        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }

    input {
      font-size: 1rem;
      border: 1px solid transparent;
      border-radius: var.$radius-sm;
      padding: 0.75rem;
      width: 100%;
      background-color: var.$lightNeutralGray;

      &:focus {
        border-color: var.$on3primary;
        outline: none;
      }
    }

    .moneyInput {
      position: relative;

      &::before {
        content: '$';
        position: absolute;
        left: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1rem;
        color: var.$neutralGray;
      }

      input {
        padding-left: 1.5rem;
      }
    }

    .valuation {
      @include mix.flex;

      margin-top: 0.5rem;
      font-size: 0.875rem;

      svg {
        height: 1rem;
        width: 1rem;
        fill: var.$on3primary;
        margin-right: 0.5rem;
      }
    }

    .submit {
      text-align: right;

      button {
        @extend %btn-primary;
      }
    }
  }

  .error {
    font-size: 0.75rem;
    text-align: left;
    color: var.$negativeDay;
  }

  .success {
    text-align: center;

    @include mix.spaceY(1rem);

    button {
      @extend %btn-gray;
    }
  }
}
